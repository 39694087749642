import React, { Component } from "react";

import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./downloads-styles";
import { isMobile, isAndroid } from "mobile-device-detect";
import Dialog from "@material-ui/core/Dialog";
import QRCode from "qrcode.react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import firebase from "firebase";
import StarIcon from "@material-ui/icons/FavoriteTwoTone";
import { Link } from "@material-ui/core";
import MobileStoreButton from "react-mobile-store-button";

function downloadFile(absoluteUrl) {
  var link = document.createElement("a");
  link.href = absoluteUrl;
  link.download = "true";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const tiers = [
  {
    title: "Babel - AR",
    description: [
      "Empieza a experimentar con Babel y la realidad aumentada, rompiendo los limites entre lo  virtual y la realidad"
    ],
    buttons: ["android"],
    buttonText: "Download",
    buttonVariant: "outlined",
    active: true
  }

  /*{
    title: "Babel - VR",
    description: [
      "Experience immersed lessons, which will help you to better understand what you want to learn"
    ],
    buttons: ["default"],
    buttonText: "Coming soon...",
    buttonVariant: "outlined",
    active: false
  }*/
];

class Downloads extends Component {
  state = {
    showDownloadQR: false,
    downloadURL: "waiting",
    mobileMoreAnchorEl: null
  };

  constructor(props) {
    super(props);

    tiers[0].action = () => {
      this.download();
    };
  }

  handleClose = () => {
    this.setState({ showDownloadQR: false });
  };

  handleOpen = () => {
    this.setState({ showDownloadQR: true });
  };

  componentDidMount() {
    this.getDownloadURL();
  }

  getDownloadURL = () => {
    const storage = firebase.storage();
    var reference = storage.ref().child("AR_Test.apk");

    reference
      .getDownloadURL()
      .then(url => {
        this.setState({ downloadURL: url });
      })
      .catch(function(error) {
        // Handle any errors
      });
  };

  downloadAPKToDevice = () => {
    return (
      <Link target="_blank" to="www.google.com">
        Google
      </Link>
    );
    //downloadFile(this.state.downloadURL);
  };

  download = () => {
    return (
      <Link target="_blank" to="www.google.com">
        Google
      </Link>
    ); /*
    if (isMobile && isAndroid) {
      this.downloadAPKToDevice();
    } else {
      this.handleOpen();
    }*/
  };

  render() {
    const { classes } = this.props;
    const androidURL =
      "https://play.google.com/store/apps/details?id=com.cunori.babel";

    return (
      <React.Fragment>
        <main className={classes.content}>
          <div className={classes.layout}>
            {/* Hero unit */}
            <div className={classes.heroContent}>
              <Typography
                component="h1"
                variant="h2"
                className={classes.textPrimary}
                align="center"
                gutterBottom
              >
                Descargas
              </Typography>
            </div>
            {/* End hero unit */}
            <Grid container spacing={1} alignItems="flex-end" justify="center">
              {tiers.map(tier => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === "Enterprise" ? 12 : 6}
                  md={4}
                >
                  <Card className={classes.card}>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      action={tier.title === "Pro" ? <StarIcon /> : null}
                      className={classes.cardHeader}
                      classes={{
                        title: classes.cardHeader
                      }}
                    />
                    <CardContent className={classes.cardContent}>
                      {tier.description.map(line => (
                        <Typography
                          className={classes.contentText}
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      {tier.buttons.includes("android") && (
                        <div className={classes.buttonContainer}>
                          <MobileStoreButton
                            store="android"
                            url={androidURL}
                            linkProps={{ title: "Google Play" }}
                            linkStyles={{
                              position: "absolute",
                              marginTop: 20,
                              left: "50%",
                              width: "60%",
                              height: "100%",
                              transform: "translate(-50%)"
                            }}
                          />
                        </div>
                      )}

                      {tier.buttons.includes("default") && (
                        <Button
                          fullWidth
                          variant={tier.buttonVariant}
                          color="primary"
                          className={classes.cardButton}
                          disabled={!tier.active}
                          onClick={tier.action}
                        >
                          {tier.buttonText}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.showDownloadQR}
          classes={{ paper: classes.dialog }}
        >
          <CardContent>
            <Typography
              className={classes.titleText}
              color="textSecondary"
              gutterBottom
            >
              {`Scan the QR with your Android Device`}
            </Typography>
          </CardContent>
          <DialogContent>
            <div className={classes.buttonContainer}>
              <MobileStoreButton
                store="ios"
                url={androidURL}
                linkProps={{ title: "Google Play Store Button" }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
          <div />
        </Dialog>
      </React.Fragment>
    );
  }
}

Downloads.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Downloads);
