import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import history from "./history";
import AuthMngr from "../login/authmngr";
import CircularProgress from "@material-ui/core/CircularProgress";
import { cancelRequests } from "../../backend-communication/cancel-axios-token";

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  content: {
    backgroundColor: theme.palette.background.dark,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  font: {
    color: theme.palette.text.main
  }
});

/**
 * Comprobar credenciales de login
 * Hacemos un GET al api y nos va a devolver
 * una sesion si existe, o un objeto authorized:false
 * si no hay ninguna sesion abierta
 */
const authenticate = (role, callback) => {
  if (!AuthMngr.getInstance().hasEmailVerification()) {
    history.push("/verify");
    return;
  }

  AuthMngr.getInstance().hasAuthorization(role, val => {
    callback(val);
  });
};

class SignedRoute extends Component {
  state = {
    authorized: false,
    hasSession: false
  };

  e = idToken => {
    //if (this.props.authenticate == null || this.props.authenticate)
    if (AuthMngr.getInstance().isSigned) this.authenticate();
    else history.push("/signin");
  };
  componentDidMount() {
    this.e = AuthMngr.getInstance().subscribeOrExecute(this.e);
  }

  componentWillUnmount() {
    cancelRequests("unmounting");
    AuthMngr.getInstance().unsubscribeEvent(this.e);
  }
  authenticate = () => {
    authenticate(this.props.role, val => {
      if (!val) history.push("/signIn");
      else {
        if (this.props.executeOnValid) this.props.executeOnValid();
        this.setState({ hasSession: true, authorized: true });
      }
    });
  };

  /**
   * Va a renderizar un icono de carga si no a traido los daots
   * y si los trajo correctamente va a renderizar la pagina
   * si no los trajo correctamente nos redireccionara al login
   */
  renderLoad = classes => {
    return <React.Fragment>{this.props.children}</React.Fragment>;
    //Esto estara mientas espera que haya traido la sesion
    if (!this.state.hasSession) {
      return <React.Fragment></React.Fragment>;
      return (
        <main className={classes.content}>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)"
            }}
          >
            <Grid container>
              <Grid container justify="center">
                <CircularProgress className={classes.progress} size={50} />
              </Grid>
              <Grid container justify="center">
                <Typography variant="h5" gutterBottom className={classes.font}>
                  Loading
                </Typography>
              </Grid>
            </Grid>
          </div>
        </main>
      );
    } else {
      //Ya trajo la sesion hay dos opciones, hay que comprobar si la sesion tiene autoridad para ver esta pagina o no
      if (this.state.authorized === true)
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
  };

  render() {
    const { classes } = this.props;
    return <React.Fragment>{this.renderLoad(classes)}</React.Fragment>;
  }
}

SignedRoute.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * Gets called when and if the user has authorization for viewing  the page
   */
  executeOnValid: PropTypes.func,
  /**
   * Role type "public" or "admin"
   */

  role: PropTypes.string
};
export default withStyles(styles)(SignedRoute);
