import axios from "axios";

let cancelTokens = [];

export function getCancelToken() {
  let cancelToken = axios.CancelToken.source();
  cancelTokens.push(cancelToken);
  return cancelToken;
}

export function cancelRequests(message) {
  // console.log(message);
  cancelTokens.forEach(c => {
    c.cancel(message);
  });
  cancelTokens = [];
}
