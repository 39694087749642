export default theme => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.background.dark + "00",
    boxShadow: "none",
    color: theme.palette.text.main
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  textTitle: {
    color: "white",
    fontFamily: "Josefin Sans"
  },
  textDescription: {
    color: "white",
    fontSize: 20
  },
  background: {
    backgroundColor: theme.palette.background.darker,
    backgroundImage: `url("https://images.unsplash.com/photo-1561089489-f13d5e730d72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80")`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "fixed",
    top: -10,
    left: -10,
    right: -10,
    bottom: -10,
    filter: "blur(7px)"
  },

  heroUnit: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  heroContent: {
    maxWidth: 600,
    position: "absolute",
    zIndex: 2,
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(6)}px`
  },
  heroContentMobile: {
    width: "80%",
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(1)}px`
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  cardGrid: {
    padding: `${theme.spacing(8)}px 0`
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fab: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.primary.tone3,
    color: theme.palette.text.main
  },
  primaryButton: {
    minWidth: 200,
    minHeight: 50,
    borderWidth: 2,
    backgroundColor: "white",
    color: "black",
    //borderColor: theme.palette.primary.tone1,
    //color: theme.palette.primary.tone3,
    borderRadius: 50
  },
  secondaryButton: {
    minWidth: 200,
    minHeight: 50,
    color: "white",
    borderWidth: 3,
    borderColor: "white",
    borderRadius: 50
  },
  dialog: { minWidth: "30%" },
  titleText: {
    fontSize: 20,
    textAlign: "center",
    paddingTop: 5,
    width: "80%",
    paddingBottom: 5,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.background.lighter
  },
  secondaryText: { marginTop: 20, fontSize: 16, textAlign: "center" },
  qrContainer: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  qr: { width: "100%" },
  buttonContainer: {
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center"
  },
  moreButton: {
    color: theme.palette.text.main
  }
});

/*
Photo by Shubham Sharan on Unsplash

<a style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@shubhamsharan?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Shubham Sharan"><span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32"><title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style="display:inline-block;padding:2px 3px">Shubham Sharan</span></a>


*/
