export default theme => ({
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.background.dark + "00",
    boxShadow: "none",
    color: theme.palette.text.main
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  background: {
    backgroundColor: theme.palette.background.darker,
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    bottom: 0,
    filter: "blur(10px)"
  },
  content: { padding: theme.spacing(3) },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(900 + theme.spacing(3 * 2))]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  textTitle: {
    color: theme.palette.background.light,
    fontWeight: 500
  },
  textDescription: {
    color: theme.palette.background.light,
    fontWeight: 400
  },

  heroContent: {
    marginTop: "50px",
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(8)}px  0 ${theme.spacing(6)}px`
  },
  card: {
    position: "relative",
    backgroundColor: theme.palette.background.light,
    borderRadius: 20,
    minHeight: 300
  },
  cardHeader: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.main
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  cardActions: {
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(2)
    },
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0
  },
  cardContent: {
    position: "absolute",
    top: "50%",
    transform: "translate(0% , -50%)"
  },

  cardButton: {
    borderRadius: 20,
    marginLeft: 20,
    marginRight: 20,
    color: theme.palette.primary.tone1,
    borderColor: theme.palette.primary.tone1,
    "&:disabled": {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.text.secondary
    },
    "&:hover": {
      color: theme.palette.primary.tone1,
      borderColor: theme.palette.primary.tone1
    }
  },

  heroContentMobile: {
    width: "80%",
    position: "absolute",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    padding: `${theme.spacing(8)}px 0 ${theme.spacing(1)}px`
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  moreButton: {
    color: theme.palette.text.main
  },
  font: {
    color: theme.palette.text.main
  },
  textPrimary: {
    fontSize: 45,
    color: theme.palette.text.main
  },
  textSecondary: {
    color: theme.palette.background.main
  },

  dialog: { minWidth: "30%" },
  titleText: {
    fontSize: 20,
    textAlign: "center",
    paddingTop: 5,
    width: "80%",
    paddingBottom: 5,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.background.lighter
  },
  contentText: {
    marginTop: 20,
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  qrContainer: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  qr: { width: "100%" },
  buttonContainer: {
    marginTop: 50
  },
  playButton: {
    width: 10
  },
  moreButton: {
    color: theme.palette.text.main
  }
});
